














import { UserLoginRequest } from '@/models/users';
import { Vue, Component, Emit } from 'vue-property-decorator';
import LoginFormTemplate from './LoginFormTemplate.vue';
import ForgetPasswordTemplate from './ForgetPasswordTemplate.vue';
@Component({
  components: { LoginFormTemplate, ForgetPasswordTemplate },
})
export default class LoginRegisterForm extends Vue {
  isLoginFormShown = true;

  @Emit(`sendRequest`)
  sendForgetPasswordEmail(data: string) {
    return data;
  }

  @Emit(`login`)
  loginUser(payload: UserLoginRequest) {
    return payload;
  }

  @Emit(`changeForm`)
  changeFormState(payload: boolean) {
    this.isLoginFormShown = payload;
    return this.isLoginFormShown;
  }
}
