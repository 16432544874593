
























import { EMAIL_REGEX } from '@/utils/regex';
import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({})
export default class ForgetPasswordTemplate extends Vue {
  currentEmail = '';

  get isEmailValid() {
    return !EMAIL_REGEX.test(this.currentEmail);
  }

  @Emit(`changeForm`)
  changeToLoginForm() {
    return true;
  }

  @Emit(`sendRequest`)
  sendForgetPasswordRequest() {
    return this.currentEmail;
  }
}
