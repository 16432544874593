

































import { UserLoginRequest } from '@/models/users';
import { Vue, Component, Emit } from 'vue-property-decorator';
@Component({})
export default class LoginFormTemplate extends Vue {
  isCurrentRoleAdmin = true;
  isSignInButtonDisabled = true;
  email = ``;
  password = ``;

  get isRoleAdmin() {
    return this.isCurrentRoleAdmin;
  }

  get isRoleTeacher() {
    return !this.isCurrentRoleAdmin;
  }

  updateEmail(payload: string) {
    this.email = payload;
    this.validateDetails();
  }

  updatePassword(payload: string) {
    this.password = payload;
    this.validateDetails();
  }

  validateDetails() {
    this.isSignInButtonDisabled = false;
    if (!this.password || !this.email || this.email.length === 0 || this.password.length === 0) {
      this.isSignInButtonDisabled = true;
    }
  }

  @Emit(`changeForm`)
  changeToForgetPasswordForm() {
    return false;
  }

  @Emit(`login`)
  loginUser() {
    const details: UserLoginRequest = {
      email: this.email,
      password: this.password,
    };
    return details;
  }
}
