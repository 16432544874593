









































import { Vue, Component, Watch } from 'vue-property-decorator';
import { convertDateToYear } from '@/utils/moment';
import LoginRegisterForm from '@/components/login/LoginRegisterForm.vue';
import { UserLoginRequest } from '@/models/users';
import { UserStores } from '@/store/users';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
@Component({
    components: { LoginRegisterForm },
})
export default class Login extends Vue {
    userLoginStore = UserStores.login;
    userProfileStore = UserStores.profile;
    isLoginFormShown = true;
  
    get currentYear() {
        return convertDateToYear(new Date());
    }

    async sendForgetPasswordEmail(data: string) {
        const response = await axios.post(`auth/forgetPassword`, { email: data });

        if (response.data && response.data.code === 200) {
            ToastProgrammatic.open({
                type: `is-success`,
                duration: 3000,
                message: `Forget password is sent to ${data}`
            });
        }
    }

    redirectToLogin() {
        if (this.$route.path !== '/login') {
            this.$router.push({
                path: `/login`
            });
        }
    }

    loginUser(payload: UserLoginRequest) {
        this.userProfileStore.setProfileRole(``);
        this.userProfileStore.setProfileName(``); 
        this.userLoginStore.loginUser(payload);
    }

    @Watch(`userLoginStore.response`)
    redirectToDashboard() {
        if (this.userLoginStore.response) {
            this.$router.push({
                path: `/timetable`
            });
        }
    }
}
